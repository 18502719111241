<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <b-container>
        <b-row align-h="center">
          <b-col md="12">
            <b-form @submit.prevent="addUsercrypto">
              <b-row class="bg-white shadow p-5">
                <b-col md="12" class="mb-4">
                  <b-form-group
                    id="input-group-1"
                    label="Select User List:"
                    label-for="userList"
                  >
                    <multiselect
                      v-model.trim="$v.form.userList.$model"
                      track-by="id"
                      label="user_name"
                      :options="slctUsers"
                      placeholder="Select Users"
                    ></multiselect>
                  </b-form-group>
                  <div
                    class="error"
                    v-if="submitted && !$v.form.userList.required"
                  >
                    User is required
                  </div>
                </b-col>
                <!-- Userlist  -->

                <b-col md="12" class="mb-4">
                  <b-form-group
                    id="input-group-1"
                    label="Select Currency:"
                    label-for="currencyList"
                  >
                    <multiselect
                      v-model.trim="$v.form.currencyList.$model"
                      track-by="symbol"
                      label="symbol"
                      :options="slctCurrency"
                      placeholder="Select Currency"
                    ></multiselect>
                  </b-form-group>
                  <div
                    class="error"
                    v-if="submitted && !$v.form.currencyList.required"
                  >
                    Currency is required
                  </div>
                </b-col>
                <!-- Currency list  -->

                <b-col md="12" class="">
                  <b-form-group
                    id="input-group-1"
                    label="Amount:"
                    label-for="ammount"
                  ></b-form-group>
                </b-col>
                <b-col md="6" class="mb-4">
                  <multiselect
                    v-model.trim="$v.form.amount_type.$model"
                    track-by="name"
                    label="name"
                    :options="slctAmountType"
                    placeholder="Select Amount Type"
                  ></multiselect>
                  <div
                    class="error"
                    v-if="submitted && !$v.form.amount_type.required"
                  >
                    Amount Type is required
                  </div>
                </b-col>
                <!-- Amount Type -->

                <b-col md="6" class="mb-4">
                  <b-form-input
                    id="amount"
                    v-model="$v.form.amount.$model"
                    type="text"
                    onpaste="return false"
                    @keypress="keyChange($event, 8)"
                    placeholder="Enter Amount"
                  >
                  </b-form-input>
                  <div
                    class="error"
                    v-if="submitted && !$v.form.amount.required"
                  >
                    Amount is required
                  </div>
                </b-col>
                <!-- Amount  -->

                <b-col md="12" class="mb-4 text-center">
                  <b-button type="submit" class="col-md-2" variant="primary"
                    >Submit</b-button
                  >
                </b-col>
                <!-- Submit Button  -->
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </Layout>
  </div>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

export default {
  // page: {
  //     title: "Add New User",
  //     meta: [{ name: "description", content: appConfig.description }],
  // },
  components: {
    Layout,
    Multiselect,
    PageHeader,
  },

  data() {
    return {
      title: "Add User Crypto",
      items: [
        {
          text: "Dashboard",
          href: "/admin",
        },
        {
          text: "Add User Crypto",
          active: true,
        },
      ],

      slctTemp2: null,
      slctUsers: [],
      slctCurrency: [],
      //slctAmountType:['deduct','add'],
      slctAmountType: [{ name: "deduct" }, { name: "add" }],
      submitted: false,
      form: {
        userList: "",
        currencyList: "",
        amount_type: "",
        amount: "",
      },
    };
  },
  validations: {
    form: {
      userList: { required },
      currencyList: { required },
      amount_type: { required },
      amount: { required },
    },
  },
  created() {
    this.getSelectdata();
  },
  methods: {
    async getSelectdata() {
      var result = await ApiClass.getRequest("admin/fund/get_fund", true);
      if (result.data.status_code == 1) {
        // console.log(result);
        // console.log(result.data.data.users);
        this.slctUsers = result.data.data.users;
        this.slctCurrency = result.data.data.currencyList;
      }
    },
    async addUsercrypto() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.submitted = false;

      var result = await ApiClass.postRequest("admin/fund/create", true, {
        user_id: this.form.userList.id,
        currency: this.form.currencyList.symbol,
        amount_type: this.form.amount_type.name,
        balance: this.form.amount,
      });
      if (result.data.status_code == 1) {
        this.reset();
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      }

      

      // if(result.data.status_code == 1){
      //     this.$router.push({ name: 'User List' })
      //  }
    },
    reset() {
      this.form = {
        userList: "",
        currencyList: "",
        ammount: "",
        amount_type: "",
      }; // Empty Fields
    },

    keyChange(event, decimal = null) {
      let res =
        (event.charCode >= 48 && event.charCode <= 57) ||
        (event.charCode == 46 && event.target.value.indexOf(".") == -1);

      if (decimal != null && res && event.target.value.indexOf(".") != -1) {
        res = event.target.value.split(".")[1].length < decimal;
      }
      return res ? res : event.preventDefault();
    },
  },
};
</script>